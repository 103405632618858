import request from './request.js'
export function getGoodList(params) {//获取
    return request({
        url: "/buy/product/list.do?",
        method: 'post',
        data: params
    })
}
export function addGoodinfo(params) {//添加
    return request({
        url: "/buy/product/add.do?",
        method: 'post',
        data: params
    })
}
export function deleteGoodinfo(params) {//删除
    return request({
        url: "/buy/product/deleteById.do?",
        method: 'post',
        data: params
    })
}
export function editGoodinfo(params) {//编辑
    return request({
        url: "/buy/product/update.do?",
        method: 'post',
        data: params
    })
}
export function distributionGoodinfoList(params) {//分销
    return request({
        url: "/buy/agent/list.do?",
        method: 'post',
        data: params
    })
}

export function distributionGoodinfo(params) {//分销
    return request({
        url: "/buy/agent/add.do?",
        method: 'post',
        data: params
    })
}
export function cancelDistributionGoodinfo(params) {//分销
    return request({
        url: "/buy/agent/deleteById.do?",
        method: 'post',
        data: params
    })
}
export function updateDistributionGoodinfo(params) {//分销
    return request({
        url: "/buy/agent/update.do?",
        method: 'post',
        data: params
    })
}


export function materialsList(params) {//材质规格列表
    return request({
        url: "/buy/fee/list.do?",
        method: 'post',
        data: params
    })
}

export function deleteMaterialsList(params) {//删除材质规格列表
    return request({
        url: "/buy/fee/deleteById.do?",
        method: 'post',
        data: params
    })
}
export function addMaterialsList(params) {//增加材质规格列表
    return request({
        url: "/buy/fee/add.do?",
        method: 'post',
        data: params
    })
}
export function allChoiceList(params) {//字典接口
    return request({
        url: "/buy/fee/allChoice.do?",
        method: 'post',
        data: params
    })
}

export function supportList(params) {//支持商品属性接口
    return request({
        url: "/buy/limit/list.do?",
        method: 'post',
        data: params
    })
}
export function addSupportList(params) {//新增支持商品属性接口
    return request({
        url: "/buy/limit/add.do?",
        method: 'post',
        data: params
    })
}
export function deleteSupportList(params) {//支持商品属性接口
    return request({
        url: "/buy/limit/deleteById.do?",
        method: 'post',
        data: params
    })
}
export function expressList(params) {//快递列表
    return request({
        url: "/buy/express/list.do?",
        method: 'post',
        data: params
    })
}
export function addExpressList(params) {//新增快递列表
    return request({
        url: "/buy/express/add.do?",
        method: 'post',
        data: params
    })
}

export function deleteExpressList(params) {//删除快递列表
    return request({
        url: "/buy/express/deleteById.do?",
        method: 'post',
        data: params
    })
}

export function editExpressList(params) {//编辑快递列表
    return request({
        url: "/buy/express/update.do?",
        method: 'post',
        data: params
    })
}

export function getorderList(params) {//商品订单记录列表
    return request({
        url: "/buy/order/list.do?",
        method: 'post',
        data: params
    })
}
export function checkOrder(params) {//审核订单
    return request({
        url: "/buy/order/check.do?",
        method: 'post',
        data: params
    })
}
export function deliverExport(params) {//信息录入
    return request({
        url: "/buy/order/deliver.do?",
        method: 'post',
        data: params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function getDetaileList(params) {//
    return request({
        url: "/buy/order/findByOrderNum.do?",
        method: 'post',
        data: params,
    })
}
export function getexpressPageList(params) {// 快递支持列表 支持分页
    return request({
        url: "/buy/express/pageList.do?",
        method: 'post',
        data: params,
    })
}

export function getLimitPageList(params) {// 支持商品支持列表 支持分页
    return request({
        url: "/buy/limit/pageList.do?",
        method: 'post',
        data: params,
    })
}
export function getLimitUpdate(params) {// 支持商品支持列表编辑
    return request({
        url: "/buy/limit/update.do?",
        method: 'post',
        data: params,
    })
}

export function getFeepageList(params) {// 材质规格列表支持分页
    return request({
        url: "/buy/fee/pageList.do?",
        method: 'post',
        data: params,
    })
}

export function getFeeupdate(params) {// 材质规格列表支持分页
    return request({
        url: "/buy/fee/update.do?",
        method: 'post',
        data: params,
    })
}
export function refundOrder(params) {// 订单退款
    return request({
        url: "/buy/order/refund.do?",
        method: 'post',
        data: params,
    })
}
export function orderPageList(params) {// 订单记录分页
    return request({
        url: "/buy/order/pageList.do?",
        method: 'post',
        data: params,
    })
}

export function orderListexportRecord(params){
    return request({
        url: '/buy/order/export.do?',
        method: 'post',
        data:params
    })
}








































