<template>
  <div class="fadeInRight orderDetails">
    <div class="title-box clearfix">
      <div class="title-box detailHeader">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/purchaseCard/orderList' }"
            >购卡记录</el-breadcrumb-item
          >
          <el-breadcrumb-item>购卡详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="content-box">
      <div class="detail_content">
        <div class="order_info">
          <div class="order_info_box">
            <div>
              <span>订单编号：{{ detailList.orderNum }}</span>
            </div>
            <div>
              <span>订单状态：{{ getStatus(detailList.status) }} </span>
              <!-- <span class="color-error">
                (<span class="countdown">29小时45分后自动关闭</span>)
              </span> -->
            </div>
            <div v-if="detailList.status == 5||detailList.status == 6">
               <span>快递公司：{{ detailList.expressName }}</span>&nbsp;
              <span>货运单号：<a :href="hrefurl" target="_blank" class="expressNum"> {{ detailList.expressNum }}</a>  </span>&nbsp;
              <span>含运费：{{ detailList.expressFee }}</span>
            </div>
          </div>
          <div class="order_info_box">
            <div>
              <span>收货人信息：</span> <span></span>
              <span>{{ detailList.addressInfo?detailList.addressInfo.split(",")[2]:"" }} </span>&nbsp;
              <span>{{ detailList.contactPhone }}</span>
            </div>
          </div>
          <!-- <div class="order_info_box">
            <div>
              <span>送货单：</span> <span>{{ deliveryOrder }}</span>
            </div>
          </div> -->
          <div class="order_info_box">
            <div>
              <span>发货清单：</span>
              <el-table :data="tableData" border fit stripe style="width: 100%">
                <el-table-column label="商品详情" prop="goodDetail" width="330">
                  <template slot-scope="props">
                    <div class="item-1-2 width-70">
                      <div class="goods-attribute">
                        <p class="margin-bottom10">
                          <span>{{ props.row.productName }}</span
                          >&nbsp;<span>{{
                            _getCategory(props.row.accountType, 1)
                          }}</span
                          >&nbsp;<span>{{ props.row.mealTypeStr }}套餐</span>
                        </p>
                        <p class="color-666 f-size-l9 left-width">
                          <span>材料：</span
                          ><span>{{ props.row.materialStr }}</span
                          ><br />
                          <span>规格：</span
                          ><span>{{ props.row.specStr }}</span>
                        </p>
                        <div class="color-666 f-size-l9 attr-rules left-width">
                          <div>
                            <span>网络模式：</span
                            ><span>{{  props.row.netModelStr }}</span>
                          </div>
                        </div>
                        <div
                          class="color-666 f-size-l9 attr-rules"
                          style="
                            float: right;
                            margin-top: -69px;
                            margin-bottom: 10px;
                          "
                        >
                          <div class="right-width">
                            <span>卡片功能：</span
                            ><span>{{ props.row.cardFuncStr == "null" ||props.row.cardFuncStr.trim() === "" ? "无" : props.row.cardFuncStr}}</span>
                          </div>
                          <!-- <div class="right-width">
                      <span>网络增值服务：</span><span>{{item.netIncreaseSer }}</span>
                    </div> -->
                          <div class="right-width">
                            <span>套餐分类：</span
                            ><span>{{ props.row.mealName }}</span>
                          </div>
                          <div class="right-width">
                            <span>已打开自动续费功能：</span
                            >{{ detailList.autoRenew == 1 ? "是" : "否" }}
                          </div>
                          <el-tooltip
                            class="item"
                            effect="dark"
                            placement="top"
                          >
                            <div
                              slot="content"
                              v-html="totalInfo(props.row)"
                            ></div>
                            <el-button type="text">显示全部</el-button>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="套餐价格" prop="mealPrice">
                  <template slot-scope="scope">
                    {{ "￥" + scope.row.mealPrice }}
                  </template>
                </el-table-column>
                <el-table-column label="总周期" prop="subscribeCount">
                  <template slot-scope="scope">
                    {{ scope.row.subscribeCount }}月
                  </template>
                </el-table-column>
                <el-table-column label="沉默期" prop="silentCount">
                  <template slot-scope="scope">
                    {{ scope.row.silentCount }}月
                  </template>
                </el-table-column>
                <el-table-column label="单张卡费" prop="singleFee">
                  <template slot-scope="scope">
                    {{ "￥" + scope.row.singleFee }}
                  </template>
                </el-table-column>
                <el-table-column label="卡片数量" prop="cardCount">
                </el-table-column>
                <el-table-column label="小计" prop="totalFee">
                  <template slot-scope="scope">
                    {{ "￥" + scope.row.totalFee }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div>
              <span>订单备注：</span><span>{{ detailList.remark }}</span>
            </div>
          </div>
          <div class="order_info_box">
            <div class="align_right">
              <div class="order_info_boxitem">
                <div>
                  <span>总卡数</span><span>{{ totalCardNum }}张</span>
                </div>
                <div>
                  <span>总计</span
                  ><span
                    >￥{{
                      (
                        parseFloat(detailList.totalFee) -
                        parseFloat(detailList.expressFee)
                      ).toFixed(2)
                    }}</span
                  >
                </div>
                <div>
                  <span>运费</span>
                  <span
                    >￥{{ parseFloat(detailList.expressFee).toFixed(2) }}</span
                  >
                </div>
                <div>
                  <span>应付金额</span
                  ><span class="color-waring"
                    >￥{{ parseFloat(detailList.totalFee).toFixed(2) }}</span
                  >
                </div>
              </div>
              <div>根据运营商规则，物联网卡一经售出不予退换</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import { getDetaileList } from "@/api/goodList.js";
import dictGroup from "@/mixins/dictGroup.js";
export default {
  name: "orderDetails",
  mixins: [onresize, dictGroup],
  data() {
    return {
      totalCardNum: 1,

      tableData: [],
      detailList: {},
    };
  },
  created() {
    if (this.$route.query.orderNum) {
      this.getDetail();
    }
  },
  watch: {},

  computed: {
     hrefurl(){
      return "https://www.kuaidi100.com/chaxun?nu="+ this.detailList.expressNum
    }
  },
  methods: {
    getStatus(status) {
      let statusStr = "";
      if (status == 1) {
        statusStr = "待支付";
      } else if (status == 2) {
        statusStr = "待审核";
      } else if (status == 3) {
        statusStr = "待发货";
      } else if (status == 4) {
        statusStr = "审核未通过";
      } else if (status == 5) {
        statusStr = "已发货";
      } else if (status == 6) {
        statusStr = "完成";
      } else if (status == 7) {
        statusStr = "退款";
      } else if (status == 8) {
        statusStr = "已取消";
      }
      return statusStr;
    },
    getDetail() {
      getDetaileList({ orderNum: this.$route.query.orderNum }).then((res) => {
        this.detailList = res;
        this.tableData = res.itemList;
        this.totalCardNum = 0;
        res.itemList.forEach((item) => {
          this.totalCardNum += item.cardCount;
        });
      });
    },

    totalInfo(item) {
      let str = "";
      let cardFuncStr = item.cardFuncStr == "null" ||item.cardFuncStr.trim() === "" ? "无" : item.cardFuncStr;
      let autoRenew = this.detailList.autoRenew == 1 ? "是" : "否";
      str +=
        "卡片功能: " +
        cardFuncStr +
        "<br>" +
        "套餐分类: " +
        item.mealName +
        "<br>" +
        "已打开自动续费功能：" +
        autoRenew;
      return str;
    },
  },
};
</script>

<style>
.orderDetails .dialogItem {
  font-size: 15px;
  margin: 20px 0;
}
.dialogItem .pay_should {
  font-size: 18px;
  color: #f65623;
}
.orderDetails .color-waring {
  color: #f65623 !important;
}
.orderDetails .order_info_boxitem > div span:first-child {
  display: inline-block;
  min-width: 120px;
  text-align: left;
}
.orderDetails .order_info_boxitem > div > span:last-child {
  display: inline-block;
  text-align: right;
  min-width: 50px;
  float: right;
}
.orderDetails .align_right {
  text-align: right;
}
.orderDetails .color-error {
  color: #d0011b !important;
}
.orderDetails .order_info {
  width: 100%;
}
.orderDetails .order_info_box {
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
}
.orderDetails .detail_footer {
  text-align: right !important;
}
.orderDetails .detail_content {
  line-height: 3em;
  margin: 15px;
}
.orderDetails .el-step__title.is-process {
  color: #333;
  font-weight: 400;
}
.orderDetails .detailHeader {
  margin-bottom: 10px;
}
.orderDetails .el-step__icon-inner {
  display: none;
}
.orderDetails .el-step__icon.is-text {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
}
.orderDetails .el-step__head.is-process .is-text {
  color: #fff;
  background-color: #bfcbd9;
  border-color: #bfcbd9;
}
.orderDetails .el-step__head.is-wait .is-text {
  color: #bfcbd9;
  background-color: #bfcbd9;
  border-color: #bfcbd9;
}
.orderDetails .el-step__title.is-wait {
  color: #333;
}
.orderDetails  .el-breadcrumb{margin-top:10px;}
.orderDetails  .goods-attribute  .right-width {
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>